<template>
  <div class="device">
    <img
      class="device-img"
      :src="require('@/assets/imgs/png/0604-a.png')"
      alt=""
    >
    <h4 class="title">請確認您要租借的行動電源編碼<br>與機台QR CODE下的編碼後四碼是否相同</h4>
    <h4 class="subtitle">(上圖紅框處)</h4>
    <perfect-scrollbar
      class="ps device-list"
      :options="{suppressScrollX:true}"
    >
      <div
        class="device-item"
        v-for="device in device_list"
        :key="device.id"
      >
        <div class="device-thumbnail">
          <img
            :src="device.img"
            alt="device thumbnail"
          >
        </div>
        <div class="device-info">
          <h3>設備編號:</h3>
          <p class="device-id">{{device.id}}</p>
        </div>
        <div
          class="rent-button"
          @click="$_rentDevice(device)"
        >
          <img
            v-if="device.status === 'using'"
            :src="require('@/assets/imgs/svg/0604-a.svg')"
            alt="using"
          >
          <img
            v-else
            :src="require('@/assets/imgs/svg/0604-b.svg')"
            alt="available"
          >
        </div>
      </div>
    </perfect-scrollbar>
    <div class="bottom-name">
      <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
    </div>
  </div>
</template>
<script>
import { handleErr } from "@/helpers";
export default {
    name: "ConfirmDevice",
    beforeCreate() {
        const devices = localStorage.getItem("devices");
        if (!devices) return;
        const tasks = [];
        JSON.parse(devices).forEach((device) => {
            tasks.push(this.$axios.get(`customer/device/detail/${device}`));
        });
        const setDevices = async () => {
            try {
                const res = await Promise.all(tasks);
                res.forEach((deviceRes) => {
                    if (!deviceRes.data.success) {
                        throw deviceRes;
                    }
                    const device = deviceRes.data.result;
                    this.device_list.push({
                        img: require("@/assets/imgs/png/0604-b.png"),
                        id: device.sn,
                        status: device.canRentNum > 0 ? "available" : "using",
                    });
                });
            } catch (error) {
                handleErr({...error, app: this.$parent});
            }
        };
        setDevices();
    },
    data() {
        return {
            device_list: [],
        };
    },
    methods: {
        $_rentDevice(device) {
            if (device.status === "using") return;
            this.$router.push(`/confirm-device/${device.id}`);
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
};
</script>
<style lang="scss" scoped>
</style>